import React from "react"
import { Link } from "gatsby"

const Pagination = () => {
  return (
    <div className="text-center">
      <ul className="pagination">
        <li>
          <Link to="/" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </Link>
        </li>
        <li className="active">
          <Link to="/">1</Link>
        </li>
        <li>
          <Link to="/">2</Link>
        </li>
        <li>
          <Link to="/">3</Link>
        </li>
        <li>
          <Link to="/">4</Link>
        </li>
        <li>
          <Link to="/">5</Link>
        </li>
        <li>
          <Link to="/" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Pagination
